@import "../../../theme-settings.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.node {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .node-left,
  .node-right {
    flex: 0 0 100%;
  }
  .node-left {
    padding: 15px;
  }
}
.node-header {
  h1.header-title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .header-datetime {
    display: flex;
    flex-wrap: wrap;
    height: 30px;
    font-size: 16px;
    .header-date {
      margin-right: 20px;
    }
    .header-date,
    .header-time {
      display: flex;
      align-items: center;
      background-position: left center;
      background-repeat: no-repeat;
      line-height: 1;
      padding-top: 5px;
    }
    .header-date {
      padding-left: 30px;
      background-image: url(../../../static/calendar.svg);
    }
    .header-time {
      padding-left: 28px;
      background-image: url(../../../static/time.svg);
    }
    @media screen and (min-width: 1400px) {
      font-size: 20px;
    }
  }
}

.node-content {
  .content-description {
    font-size: 16px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    padding: 30px 0;
    margin: 20px 0;
    max-width: 800px;
    @media screen and (min-width: 1400px) {
      font-size: 20px;
    }
  }
  .content-details {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .details-buttons,
    .details-summary {
      flex: 0 0 100%;
    }
    @media screen and (min-width: 768px) {
      .details-buttons {
        order: 1;
        flex: 0 0 auto;
        max-width: 360px;
      }
      .details-summary {
        order: 2;
        flex: 0 0 calc(100% - 360px);
      }
    }
    .details-buttons {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      button {
        flex: 0 0 100%;
        margin-right: 30px;
        margin-bottom: 15px;
        max-width: 300px;
        min-width: 300px;
      }
      button.green {
        border-color: transparent;
        background: #09b437;
        color: white;
      }
      button.red {
        border-color: transparent;
        background: #cf3d3d;
        color: white;
      }
    }
    .details-summary {
      font-size: 20px;
      margin: 10px 0;
      .additional-description {
        margin-bottom: 30px;
      }
      p {
        margin-bottom: 10px;
      }
      .tag-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .payment-status {
        .tag-text {
          margin-right: 10px;
        }
        .ant-tag {
          line-height: 1;
          font-size: 14px;
          padding: 8px 10px;
        }
      }
      .countdown {
        display: flex;
        line-height: 1;
        align-items: center;
        span {
          margin-right: 5px;
        }
        .ant-statistic-content {
          color: black;
          font-family: $textFont;
          font-weight: bold;
          font-size: 20px;
          line-height: 1;
        }
      }
    }
  }
}

.media {
  margin: 15px 0;
  iframe,
  img {
    max-width: 100%;
  }
  .teaser-container {
    text-align: center;
    iframe {
      height: 230px;
      width: 409px;
      @media screen and (min-width: 576px) {
        height: 300px;
        width: 534px;
      }
      @media screen and (min-width: 768px) {
        width: 640px;
        height: 360px;
      }
      @media screen and (min-width: 992px) {
        width: 819px;
        height: 460px;
      }
    }
  }
  .image-container {
    text-align: center;
    margin-bottom: 20px;
  }
  .gallery-container {
    .image-gallery-icon {
      &:hover {
        color: $primary-color;
      }
      svg {
        width: 30px;
        height: 60px;
      }
    }
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:hover,
    .image-gallery-thumbnail:focus {
      border: 3px solid $primary-color;
    }
    @media screen and (min-width: 1300px) {
      .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: 100%;
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .node {
    .node-left {
      flex: 0 0 55%;
      padding: 30px;
    }
    .node-right {
      flex: 0 0 45%;
    }
  }
  .media {
    margin-top: 0;
    padding: 30px;
  }
}

@media screen and (min-width: 1600px) {
  .node {
    .node-left {
      flex: 0 0 45%;
      padding: 35px;
    }
    .node-right {
      flex: 0 0 55%;
    }
  }
  .media {
    padding: 35px;
  }
}

.node-content .content-details .details-buttons {
  .buttonGroup {
    display: flex;
    flex: 0 0 100%;
    button:first-child {
      max-width: 240px;
      min-width: 240px;
      margin-right: 5px;
    }
    button:last-child {
      flex: 0 0 55px !important;
      min-width: 55px !important;
    }
  }
}

.ant-popover-inner-content {
  max-width: 450px;
}
