.languageSelector {
  font-size: 16px;
  .languageButton {
    outline: none;
    background: none;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    padding: 0;
    border: none;
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
    &:not(:last-child)::after {
      padding-left: 5px;
      content: "/";
    }
    &.active {
      font-weight: bold;
    }
  }
}
.languageSwitcher {
  margin-top: 30px;
}
