.admin-panel {
  > .ant-card-body {
    display: flex;
  }
  .admin-menu-container {
    flex: 0 0 220px;
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
  .admin-block {
    flex: 0 0 100%;

    select {
      padding: 5px 10px;
      border: 1px solid black;
      background: transparent;
    }
    @media screen and (min-width: 768px) {
      flex: 0 0 calc(100% - 220px);
      max-width: calc(100% - 200px);
    }
  }
  .chartjs-render-monitor {
    width: 100% !important;
    max-width: 100%;
  }
  .payment-list {
  }
}
