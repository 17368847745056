.banner-block {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 575px) {
  .banner-block-tablet,
  .banner-block-desktop {
    display: none;
  }
}
@media screen and (min-width: 576px) and (max-width: 991px) {
  .banner-block-mobile,
  .banner-block-desktop {
    display: none;
  }
  .banner-block-tablet,
  .banner-block-mobile-override {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .banner-block-mobile,
  .banner-block-tablet {
    display: none;
  }
  .banner-block-desktop,
  .banner-block-mobile-override,
  .banner-block-tablet-override {
    display: block;
  }
}
