@import "../../../theme-settings.scss";

.payment-block {
  font-family: $textFont;
  font-weight: 400;
  padding-bottom: 50px;
  font-size: 20px;
  background: white;
  padding: 30px 20px;
  width: 100%;
  .warning {
    color: $red-color;
    font-weight: bold;
  }
  small {
    font-size: 15px;
  }
  p {
    margin: 0;
  }
  h2 {
    font-family: $headersFont;
    font-weight: bold;
    margin: 0;
    font-size: 40px;
  }
  .product-description {
    max-width: 430px;
    margin: 20px 0;
  }
  .ant-checkbox {
    top: -0.15rem;
  }
  .payment-checkboxes .ant-checkbox + span {
    font-size: 18px;
  }
  .payment-checkboxes {
    padding: 22px 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    max-width: 430px;
  }
  .payment-price {
    max-width: 430px;
    padding: 35px 0;
    border-bottom: 1px solid black;
    &:last-child {
      border-bottom-width: 0;
      padding-bottom: 0;
    }
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;
    .final-price {
      margin: 0;
    }
    .payment-button {
      margin-top: 30px;
      margin-bottom: 0;
    }
    del {
      color: red;
      font-size: 20px;
      line-height: 21px;
      margin: 0;
    }
    span {
      display: inline-block;
      margin-left: 15px;
      font-weight: normal;
      font-size: 18px;
      line-height: 19px;
    }
  }
  small,
  .payment-button-desc {
    margin-top: 5px;
    font-size: 15px;
    line-height: 16px;
    font-weight: normal;
    display: inline-block;
  }
  small {
    display: block;
  }
  .ant-checkbox-wrapper {
    display: block;
    & + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
  .payment-button {
    margin: 10px 0;
    margin-right: 30px;
    margin-bottom: 15px;
    max-width: 430px;
    width: 100%;
    &.green {
      border-color: transparent;
      background: #09b437;
      color: white;
    }
    &.red {
      border-color: transparent;
      background: #cf3d3d;
      color: white;
    }
  }
  .additional-payments {
    padding: 35px 0;
    button {
      margin: 0;
    }
    small {
      max-width: 430px;
      margin-bottom: 30px;
    }
  }
}

.invoice-form {
  .ant-radio-button-wrapper {
    margin-bottom: 35px;
    border-width: 3px;
    font-weight: bold;
    font-size: 20px;
    span {
      margin-top: -3px;
    }
    &.ant-radio-button-wrapper-checked {
      background: $primary-color;
      border-color: $primary-color;
      border-right-color: $primary-color !important;
      color: white;
      &::before {
        background: $primary-color;
      }
    }
    &:not(.ant-radio-button-wrapper-checked):hover,
    &:not(.ant-radio-button-wrapper-checked):focus {
      background: black;
      border-color: black;
      border-right-color: black !important;
      color: white;
      &::before {
        background: black;
      }
    }
  }
  .ant-form {
    max-width: 380px;
  }
  padding: 20px 25px 0 25px;
  display: block;
}

.cb-error {
  color: red;
}
.quantity-form {
  max-width: 420px;
  display: block;
  font-weight: bold;
  .quantity-form-error {
    font-weight: normal;
    color: red;
    font-size: 15px;
    display: block;
    height: 20px;
  }
  input {
    margin-left: 15px;
    display: inline !important;
    width: 60px;
  }
  small {
    font-size: 15px;
    margin: 25px 0;
  }
}
