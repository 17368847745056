@import "../../theme-settings.scss";

.mini-footer {
  position: relative;
  background-color: $primary-color;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  span {
    margin-right: 15px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    min-width: 220px;
    img {
      margin-left: 10px;
      height: 28px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: white !important;
  }

  > a img {
    height: 24px;
  }

  @media screen and (min-width: 768px) {
    padding: 30px 60px;
    span {
      min-width: 240px;
      margin-right: 10px !important;
      margin-bottom: 0;
    }
  }
}
.mini-footer-variant-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 20px;
  font-size: 17px;
  display: none;
  span {
    color: white;
    margin-right: 5px;
  }
  img {
    height: 23px;
  }
  @media screen and (min-width: 576px) and (min-height: 640px) {
    display: block;
  }
}
