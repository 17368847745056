@import "./theme-settings.scss";

body {
  font-family: $textFont, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  *,
  input,
  .ant-input {
    font-variant-ligatures: none;
    no-common-ligatures: none;
  }
}
html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  line-height: 1.3;
}

.ant-btn {
  font-size: 20px;
  height: unset;
  padding: 5px 15px;
}
.ant-btn-sm {
  // border-width: 2px;
  // padding: 2px 10px;
  // font-size: 13px;
}
h1,
.path-home h2,
.path-strona-glowna h2 {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 30px;
  font-family: $headersFont;
}
img {
  max-width: 100%;
}
.ant-table-content {
  overflow: auto;
}
.ant-result-content {
  max-width: 1000px;
  margin: 0 auto;
}
.ant-form-inline .ant-form-item {
  align-items: center;
}
@media screen and (min-width: 576px) {
  h1 {
    font-size: 35px;
  }
}

// USERPAGE
.users-orders-table table > thead > tr > th:nth-child(4) {
  min-width: 300px;
}
table p {
  margin-bottom: 0;
}
button,
.ant-btn {
  font-family: $headersFont;
  font-weight: 700;
  &:not(.ant-alert-close-icon) {
    font-size: 17px !important;
  }
  @media screen and (min-width: 576px) {
    font-size: 20px;
  }
  &[disabled] {
    border-color: transparent;
    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
  &.blue {
    background-color: $blue-color;
    border-color: $blue-color;
    color: white;
    &:hover,
    &:focus {
      opacity: 0.85;
      &[disabled] {
        opacity: 1;
      }
    }
  }
  &.green {
    background-color: $green-color;
    border-color: $green-color;
    color: white;
    &:hover,
    &:focus {
      opacity: 0.75;
      &[disabled] {
        opacity: 1;
      }
    }
  }
  &.orange {
    background-color: $orange-color;
    border-color: $orange-color;
    color: white;
    &:hover,
    &:focus {
      opacity: 0.75;
      &[disabled] {
        opacity: 1;
      }
    }
  }
  &.red {
    background-color: $red-color;
    border-color: $red-color;
    color: white;
    &:hover,
    &:focus {
      opacity: 0.75;
      &[disabled] {
        opacity: 1;
      }
    }
  }
}

// summary
.content .ant-result-title {
  line-height: 1.3;
}
.content .ant-result-extra a {
  display: inline-block;
  margin-bottom: 20px;
}

#CookieConsent {
  button {
    font-size: 14px !important;
  }
}

.bar button {
  font-size: 0 !important;
}

@media print {
  .main-navbar {
    background: transparent;
    display: static;
    .logo {
      img {
        display: none;
      }
      span {
        color: black;
      }
    }
    .right-col {
      display: none;
    }
  }
  .footer-left,
  .ant-result-extra,
  .CookieConsent,
  button {
    display: none !important;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .ant-input-affix-wrapper {
      max-height: 100%;
      min-height: 26px;
      input.ant-input {
        min-height: 20px;
        padding: 0;
      }
    }
  }
}

.ant-alert-warning.ant-alert-with-description {
  .ant-alert-close-icon {
    margin-top: 10px;
    position: static;
  }
}

.payment-result {
  .ant-result-content {
    margin-top: 30px;
  }
}
