@import "../../../../../theme-settings.scss";

.draggable-row {
  td {
    padding: 10px;
    img {
      max-width: 180px;
    }
  }
}
