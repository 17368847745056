.festival-card {
  width: 100%;
  background: white;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.gap {
    visibility: hidden;
  }
  &.finished {
    opacity: 0.4;
  }
  .event-image-container {
    height: auto;
    width: 100%;
    .event-image {
      width: 100%;
      padding-bottom: 100%;
      background-size: cover;
    }
  }
  .event-details {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .event-title {
      a {
        font-weight: bold;
        line-height: 1.1;
        color: black;
        font-size: 30px;
        display: block;
        padding-bottom: 15px;
      }
      .event-desc {
        font-size: 18px;
      }
      margin-bottom: 60px;
    }
    .event-summ {
      border-top: 2px solid black;
      .event-date {
        margin: 5px 0 10px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 16px;
        .day,
        .time {
          margin-top: 5px;
          display: flex;
          align-items: center;
          background-position: left center;
          background-repeat: no-repeat;
          line-height: 1;
          padding-top: 5px;
        }
        .day {
          padding-left: 30px;
          background-image: url(../../../static/calendar.svg);
          margin-right: 5px;
        }
        .time {
          padding-left: 28px;
          background-image: url(../../../static/time.svg);
        }
      }
      .event-payment {
        font-size: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .festival-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .festival-card {
      flex: 0 0 48%;
    }
  }
}

@media screen and (min-width: 992px) {
  .festival-list {
    .festival-card {
      flex: 0 0 32%;
    }
  }
}

@media screen and (min-width: 1400px) {
  .festival-list {
    .festival-card {
      flex: 0 0 24%;
    }
  }
}

@media screen and (min-width: 1600px) {
  .festival-list {
    .festival-card {
      flex: 0 0 19%;
    }
  }
}
