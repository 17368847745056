@import "../../theme-settings.scss";
.required {
  label::before {
    content: "*";
    display: block;
    margin-right: 2px;
    color: $red-color;
  }
}
.teapp-input {
  border: none;
  border-bottom: 1px solid #707070;
}
.ant-input {
  border: none;
  border-bottom: 1px solid #707070;
  font-size: 15px;
  font-weight: bold;
  &::placeholder {
    color: $placeholder-color;
  }
}
