@import "../../theme-settings.scss";

.single-button {
  height: unset;
  font-size: 18px;
  padding: 5px 15px;
  &:hover,
  &:focus {
    color: $primary-color;
    border-color: $primary-color;
  }
  &.primary {
    &:hover,
    &:focus {
      color: white;
      border-color: transparent;
    }
  }
  &.primary-bordered {
    border-color: $primary-color;
    color: $primary-color;
    &:hover,
    &:focus {
      background-color: $primary-color;
      color: white;
    }
  }
  &.filled {
    color: white;
    background-color: black;
    &:hover,
    &:focus {
      color: white;
      background-color: $primary-color;
      border-color: $primary-color;
    }
    &.filled-disabled {
      &:hover,
      &:focus {
        background-color: black;
        border-color: black;
      }
    }
    &[disabled] {
      background-color: black;
      color: white;
      border-color: black;
    }
  }
  @media screen and (min-width: 576px) {
    font-size: 20px;
  }
}
