@import "../../theme-settings.scss";

.mini-header {
  display: flex;
  align-items: center;
  padding: 30px;
  padding-bottom: 0;
  img {
    width: $miniNavbarLogoWidthSm;
    margin-right: 35px;
    border-radius: 0;
  }
  &.horizontal {
    flex-direction: column;
    align-items: flex-start;
    img,
    .mini-header-text {
      flex: 100%;
    }
    img {
      margin-bottom: 20px;
    }
  }
  .mini-header-text {
    h1,
    h2 {
      margin-bottom: 0;
      font-size: 23px;
      font-weight: bold;
      line-height: 28px;
      color: black;
      display: block;
      &:first-child {
        font-weight: 500;
      }
    }
  }
  @media screen and (min-width: 768px) {
    padding: 60px;
    padding-bottom: 0;
    img {
      width: $miniNavbarLogoWidthMd;
      margin-right: 35px;
    }
    .mini-header-text {
      h1,
      h2 {
        font-size: 30px;
        line-height: 33px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    img {
      width: $miniNavbarLogoWidthLg;
    }
  }
}
