@import "./../../../theme-settings.scss";

.slick-dots {
  position: absolute;
  top: 270px;
  bottom: unset;
  li {
    button {
      border: 3px solid white;
      width: 15px;
      height: 15px;
      transition: 0.3s;
      &::before {
        content: "";
      }
      &:hover,
      &:focus {
        background: $primary-color;
        border-color: $primary-color;
      }
    }
    &.slick-active button {
      background: white;
    }
  }
}

.slide {
  height: 100%;
  min-height: 400px;
  position: relative;
  .slide-content {
    max-width: 2410px;
    margin: 0 auto;
  }
  .slide-bg {
    background-size: cover;
    background-position: center center;
    min-height: 300px;
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .slide-desc {
    background: black;
    color: white;
    padding: 20px;
    font-size: 15px;
    .slide-title {
      display: block;
      font-weight: bolder;
      font-family: $headersFont;
      font-size: 30px;
      margin-bottom: 10px;
      line-height: 1.1;
    }
  }
}

@media screen and (min-width: 768px) {
  .slide {
    min-height: 400px;
    .slide-bg {
      position: absolute;
      min-height: 100%;
      z-index: -1;
    }
    .slide-desc {
      background: white;
      color: black;
      position: absolute;
      bottom: 20px;
      left: 0;
      width: auto;
      max-width: 400px;
      font-size: 18px;
      padding: 30px;
      .slide-title {
        font-size: 35px;
      }
    }
  }
  .slick-dots {
    width: auto;
    right: 20px;
    bottom: 20px;
    top: unset;
  }
}

@media screen and (min-width: 1024px) {
  .slide {
    min-height: 400px;
    .slide-desc {
      padding: 30px 45px;
    }
  }
}

@media screen and (min-width: 2200px) {
  .slide {
    min-height: 400px;

    .slide-content {
      min-height: 400px;
      position: relative;
    }
  }
}

.slider-2 {
  @media screen and (min-width: 768px) {
    .slide {
      display: flex;
      flex-direction: row-reverse;
      .slide-bg,
      .slide-content {
        position: static;
        flex: 0 0 350px;
      }
      .slide-content {
        display: flex;
        align-items: flex-end;
        background: $slideBg;
      }
      .slide-desc {
        position: static;
        background: $slideBg;
        color: white;
      }
      .slide-bg {
        min-height: 400px;
        flex: 0 0 calc(100% - 350px);
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .slide {
      .slide-content {
        flex: 0 0 400px;
        .slide-desc {
          padding: 45px;
        }
      }
      .slide-bg {
        flex: 0 0 calc(100% - 400px);
      }
    }
  }
  @media screen and (min-width: 1400px) {
    .slide {
      .slide-content {
        flex: 0 0 33.33%;
        .slide-desc {
          max-width: calc(100% - 50px);
          .slide-title {
            font-size: 45px;
          }
          .slide-text {
            font-size: 20px;
          }
        }
      }
      .slide-bg {
        flex: 0 0 calc(100% - 33.33%);
      }
    }
  }
  @media screen and (min-width: 1600px) {
    .slide {
      .slide-content {
        flex: 0 0 35%;
        .slide-desc {
          max-width: calc(100% - 100px);
          .slide-title {
            font-size: 50px;
          }
        }
      }
      .slide-bg {
        flex: 0 0 calc(100% - 35%);
      }
    }
  }
  @media screen and (min-width: 2300px) {
    .slide {
      background: $slideBg;
      .slide-content {
        max-width: calc(35vw - ((100vw - 2400px) / 2));
        margin: 0;
      }
      .slide-bg {
        z-index: 1;
      }
    }
  }
}

.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
    > div {
      height: 100%;
    }
}