@import "../../theme-settings.scss";

footer {
  border-top: 2px solid black;
  margin-top: 30px;
  .footer-wrapper {
    max-width: 2360px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 20px;
    padding-top: 15px;

    > div {
      flex: 0 0 100%;
    }
    .footer-left {
      a,
      div {
        display: block;
        font-size: 14px;
        margin-right: 15px;
        color: black;
        font-weight: 500;
        margin-bottom: 5px;
        white-space: normal;
        &:focus,
        &:hover {
          color: $primary-color;
        }
      }
      margin-bottom: 10px;
    }
    .footer-right {
      .mini-footer {
        color: black;
        background: transparent;
        padding: 0;
        &:hover,
        &:focus,
        &:active {
          color: black !important;
        }
        span {
          font-size: 15px;
          margin-bottom: 0;
          img {
            height: 24px;
          }
        }
        > img {
          height: 24px;
          margin-top: 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 380px) {
  footer {
    .footer-wrapper {
      .footer-left {
        a,
        div {
          display: inline-block;
        }
      }
      .footer-right {
        .mini-footer {
          span {
            img {
              height: 26px;
            }
          }
          > img {
            height: 26px;
            margin-top: 0px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  footer {
    .footer-wrapper {
      .footer-left {
        a,
        div {
          display: inline-block;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  footer {
    .footer-wrapper {
      padding: 22px 20px 0 20px;
      margin: 0 auto;
      .footer-left {
        flex: 0 0 calc(100% - 350px);
        margin-bottom: 0;
        display: flex;
        align-items: center;
        a,
        div {
          font-size: 15px;
          margin-bottom: 0;
        }
      }
      .footer-right {
        flex: 0 0 350px;
        .mini-footer {
          justify-content: flex-end;
          span {
            font-size: 17px;
            margin-right: 30px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1224px) {
  footer {
    .footer-wrapper {
      padding: 22px 20px 0 20px;
      .footer-left a,
      .footer-left div {
        font-size: 18px;
      }
      .footer-right .mini-footer span {
        font-size: 18px;
        min-width: 260px;
      }
    }
  }
}

.content-wrapper-full {
  footer {
    margin: 0 30px;
    @media screen and (min-width: 1024px) {
      margin: 0 45px;
    }
    .footer-wrapper {
      margin: 0 auto;
    }
  }
}
