@import "./../../../theme-settings.scss";

.admin-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    a {
      color: black;
    }
    img {
      width: 25px;
      margin-right: 10px;
    }
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    transition: 0.3;
    &:hover,
    &.active,
    &:focus {
      cursor: pointer;
      color: $primary-color;
      img {
        filter: invert(58%) sepia(65%) saturate(475%)
          hue-rotate(342deg) brightness(107%) contrast(92%);
      }
      a {
        color: $primary-color;
      }
    }
  }
}
