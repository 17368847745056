@import "./../../theme-settings.scss";

.noauth-layout {
  padding: 40px 10px;
  padding: 0;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  background: none;
  .ant-card-body {
    padding: 0;
  }
  .fullsize-bg,
  .fullsize-bg {
    top: 0;
    left: 0;
    content: "";
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-image: url(../../static/bg.jpg);
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
  > main {
    max-width: 542px;
    background-color: white;
    margin: 0px;
    flex: unset;
    .wrapper-content {
      padding: 30px;
    }
    .ant-result {
      padding: 0;
    }
    .ant-result-title {
      line-height: 1.3;
    }
  }
  @media screen and (min-width: 576px) {
    padding: 40px 10px;
  }
  @media screen and (min-width: 768px) {
    > main {
      min-width: 542px;
      .wrapper-content {
        padding: 50px 60px;
        padding-bottom: 30px;
      }
    }
  }
  @media screen and (min-width: 1024px) and (min-height: 1140px) {
    > main {
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translate(0%, -50%);
    }
  }
}

.auth-layout {
  background-color: $wrapper-bg-color;
  min-height: 100%;
  > main {
    min-height: 100%;
    flex: 0 0 1;
  }
  .content-wrapper-full {
    margin-top: 58px;
    margin-bottom: 22px;
  }
  .content-wrapper {
    max-width: 2410px;
    margin: 0 auto;
    min-height: calc(100vh - 58px);
    margin-top: 58px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .content > h1,
    .content > h2,
    .content #prezent > h2 {
      padding: 20px 20px 0px 20px;
      margin-bottom: 20px;
    }
  }
  &.full-width .content-wrapper {
    margin-top: 0;
  }
  @media screen and (min-width: 768px) {
    .content-wrapper {
      padding: 30px;
      padding-bottom: 22px;
      .content {
        h1,
        h2,
        #prezent > h2 {
          padding-left: 0;
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    .content-wrapper {
      // min-height: calc(100vh - 103px);
      padding-bottom: 22px;
      padding: 30px;
      .content > h1,
      .content > h2,
      .content #prezent > h2 {
        margin-bottom: 30px;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .content-wrapper {
      // min-height: calc(100vh - 163px);
      padding: 45px;
      padding-top: 30px;
      padding-bottom: 22px;
    }
  }
}

@media screen and (max-width: 576px) {
  .noauth-layout {
    min-height: 100%;
    &::before {
      display: none;
    }
    display: block;
    > main {
      min-height: 100%;
      > .ant-card {
        min-height: 100%;
        .ant-card-body {
          &::before,
          &::after {
            display: none;
          }
          min-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}

@media screen and (min-width: $navbarDrawerBreakpoint) {
  .auth-layout {
    .content-wrapper-full,
    .content-wrapper {
      margin-top: 0;
    }
  }
}

.auth-layout:not(.full-width) .content-wrapper {
  margin-top: #{$wrapperMinheightXs};
  min-height: calc(100vh - #{$wrapperMinheightXs});
  @media screen and (min-width: 768px) {
    min-height: calc(100vh - #{$wrapperMinheightSm});
  }
  @media screen and (min-width: 900px) {
    margin-top: 0;
    min-height: calc(100vh - #{$wrapperMinheightMd});
  }
  @media screen and (min-width: 992px) {
    min-height: calc(100vh - #{$wrapperMinheightLg});
  }
  @media screen and (min-width: 1200px) {
    min-height: calc(100vh - #{$wrapperMinheightXl});
  }
}
.auth-layout.full-width {
  .content-wrapper-full {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - #{$wrapperFWMinheightXs});
    @media screen and (min-width: 768px) {
      min-height: calc(100vh - #{$wrapperFWMinheightSm});
    }
    @media screen and (min-width: 900px) {
      min-height: calc(100vh - #{$wrapperFWMinheightMd});
    }
    @media screen and (min-width: 992px) {
      min-height: calc(100vh - #{$wrapperFWMinheightLg});
    }
    @media screen and (min-width: 1200px) {
      min-height: calc(100vh - #{$wrapperFWMinheightXl});
    }
    .content-wrapper {
      min-height: 0 !important;
    }
  }
}
