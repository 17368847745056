@import "../../theme-settings.scss";
.main-navbar {
  background-color: $header-bg-color;
  color: white;
  z-index: 1001;
  position: absolute;
  width: 100%;
  padding: 15px 20px;
  .main-navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 2320px;
    margin: 0 auto;
  }
  .logo {
    a {
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 15px;
      color: white;
      font-size: 20px;
      font-weight: bold;
    }
    img {
      width: $navbarLogoWidthSm;
    }
  }
  .navbar-menu {
    display: none;
  }
  .barsMenu {
    svg {
      height: 25px;
      width: 25px;
    }
  }
}
.navbar-drawer {
  .ant-drawer-content-wrapper {
    height: auto !important;
  }
  .ant-drawer-content {
    background: $header-bg-color;
    color: white;
  }
}
.navbar-menu {
  > ul {
    margin-top: 58px;
    list-style-type: none;
    padding-left: 0;
    li {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: flex;
        align-items: center;
        color: white;
        font-weight: 500;
        font-size: 15px;
        transition: 0.1s;
        &:hover,
        &:focus {
          color: $primary-color;
          filter: invert(49%) sepia(79%) saturate(1383%)
            hue-rotate(350deg) brightness(97%) contrast(87%);
        }
        img {
          margin-left: 10px;
          width: 30px;
        }
      }
      &.search-input {
        a:hover,
        a:focus {
          color: white;
          filter: none;
        }
        div,
        form {
          display: flex;
          align-items: center;
        }
        input::placeholder {
          transition: 0.3s;
          color: white !important;
        }
        input:focus,
        input:hover {
          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }
        input {
          line-height: 1;
          background: none;
          border: none;
          color: white;
          max-width: 130px;
          border-bottom: 2px solid white;
        }
        img {
          cursor: pointer;
          height: 30px;
          width: 30px;
        }
        button {
          background: none;
          border: none;
          &:hover,
          &:focus {
            img {
              filter: invert(49%) sepia(79%) saturate(1383%)
                hue-rotate(350deg) brightness(97%) contrast(87%);
            }
          }
        }
      }
      ul {
        list-style-type: none;
        padding: 0;
        padding-left: 20px;
        margin-top: 10px;
        li {
          margin: 10px 0;
        }
      }
    }
  }
}
@media screen and (min-width: $navbarDrawerBreakpoint) {
  .navbar-drawer {
    display: none;
  }
  .main-navbar {
    position: static;
    padding: 30px 45px;
    .logo {
      span {
        margin-left: 35px;
        font-size: 25px;
      }
      img {
        width: $navbarLogoWidthMd;
      }
    }
    .barsMenu {
      display: none;
    }
    .navbar-menu {
      display: block;
      > ul {
        li ul {
          display: none;
        }
      }
    }
    &.searchbar-show {
      .searchInput {
        max-width: 130px;
        @media screen and (min-width: 1200px) {
          max-width: 180px;
        }
      }
      .navbar-menu {
        > ul {
          li {
            margin-right: 25px;
            a {
              font-size: 15px;
            }
            @media screen and (min-width: 1200px) {
              margin-right: 40px;
              a {
                font-size: 20px;
              }
            }
          }
        }
        &.admin-logged-in {
          > ul {
            display: flex;
            align-items: center;
            li:not(.search-input) {
              a {
                font-size: 0;
              }
            }
          }
        }
      }
    }
  }
  .navbar-menu {
    ul {
      margin: 0;
      li {
        margin: 0;
        margin-right: 40px;
        display: inline-block;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .main-navbar {
    padding: 50px 45px;
    .logo {
      span {
        font-size: 30px;
      }
      img {
        width: $navbarLogoWidthLg;
      }
    }
  }

  .navbar-menu {
    ul {
      li {
        a {
          font-size: 20px;
        }
        &:last-child {
          margin-right: 30px;
        }
        &.search-input {
          input {
            max-width: 180px;
          }
        }
      }
    }
  }
}
