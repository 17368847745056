@import "../../../theme-settings.scss";

.search-section {
  h2 {
    font-weight: bold;
  }
}
.search-result {
  max-width: 500px;
  margin-bottom: 20px;
  a {
    h3 {
      color: $primary-color;
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
}
.search-section,
.no-result {
  padding: 0 20px;
  @media screen and (min-width: 768px) {
    padding: 0;
  }
}
