@import "../../../theme-settings.scss";

.page-content {
  font-family: $textFont;
  font-weight: 400;
  max-width: 800px;
  padding-bottom: 50px;
  font-size: 16px;
  padding: 0 20px;
  @media screen and (min-width: 768px) {
    padding: 0;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5,
  h6 {
    font-size: 18px;
  }
  ol {
    padding-left: 20px;
  }
  ul {
    padding-left: 20px;
  }
}
