@import "../../../theme-settings.scss";

.event-card {
  &.not-published {
    opacity: 0.4;
  }
  &.hidden {
    opacity: 0.4;
  }
  background: white;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .invisible-break {
    display: block;
  }
  .event-price {
    font-size: 16px;
    font-weight: 500;
  }
  .event-title {
    margin: 10px 0;
    font-size: 30px;
    font-weight: 700;
    font-family: $headersFont;
    a {
      color: black;
      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
    .event-subtitle {
      display: block;
      font-weight: normal;
      font-size: 22px;
    }
  }
  .event-date {
    font-size: 15px;
    font-weight: 500;
  }
  .event-payment-status {
    button {
      margin-top: 15px;
      width: 170px;
    }
    .blue {
      background-color: $blue-color;
      border-color: $blue-color;
      color: white;
      &:hover,
      &:focus {
        opacity: 0.85;
        &[disabled] {
          opacity: 1;
        }
      }
    }
    .green {
      background-color: $green-color;
      border-color: $green-color;
      color: white;
      &:hover,
      &:focus {
        opacity: 0.75;
        &[disabled] {
          opacity: 1;
        }
      }
    }
    .orange {
      background-color: $orange-color;
      border-color: $orange-color;
      color: white;
      &:hover,
      &:focus {
        opacity: 0.75;
        &[disabled] {
          opacity: 1;
        }
      }
    }
    .red {
      background-color: $red-color;
      border-color: $red-color;
      color: white;
      &:hover,
      &:focus {
        opacity: 0.75;
        &[disabled] {
          opacity: 1;
        }
      }
    }
  }
  @media screen and (min-width: 576px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .event-payment-status {
      button {
        margin: 0;
      }
    }
    .event-title {
      flex: 0 0 100%;
      margin-bottom: 0;
      margin-top: 20px;
      order: 1;
    }
  }
  @media screen and (min-width: 768px) {
    .event-price,
    .event-date {
      font-size: 18px;
    }
    .event-title {
      font-size: 35px;
      .event-subtitle {
        display: block;
        font-size: 25px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    justify-content: flex-start;
    .event-date,
    .event-price {
      flex: 0 0 30%;
    }
    .event-title,
    .event-payment-status {
      flex: 0 0 65%;
      margin: 0;
      margin-left: 5%;
      order: unset;
    }
    .event-price,
    .event-payment-status {
      margin-top: 20px;
    }
  }
  @media screen and (min-width: 1200px) {
    .invisible-break {
      display: none;
    }
    padding: 20px 35px;
    .event-date {
      flex: 0 0 300px;
      font-size: 18px;
    }
    .event-price {
      flex: 0 0 200px;
      text-align: center;
      margin: 0;
      font-size: 18px;
    }
    .event-payment-status {
      text-align: right;
      flex: 0 0 200px;
      margin: 0;
      button {
        font-size: 20px;
        width: 200px;
      }
    }
    .event-title {
      font-size: 30px;
      margin: 0 30px;
      flex: 0 0 calc(100% - 300px - 200px - 200px - 60px);
      .event-subtitle {
        display: block;
        font-size: 22px;
      }
    }
  }
  @media screen and (min-width: 1400px) {
    .event-date {
      flex: 0 0 335px;
    }
    .event-title {
      font-size: 35px;
      flex: 0 0 calc(100% - 335px - 200px - 200px - 60px);
      .event-subtitle {
        display: block;
        font-size: 25px;
      }
    }
    .event-date,
    .event-price {
      font-size: 20px;
    }
  }
  @media screen and (min-width: 1600px) {
    .event-title {
      font-size: 40px;
      .event-subtitle {
        display: block;
        font-size: 27px;
      }
    }
  }
}
