.teapp-checkbox {
  font-size: 11px;
  font-weight: 500;
  display: flex;
  line-height: 1.3;
  align-items: center;
  &.teapp-checkbox-big {
    font-size: 13px;
    margin-bottom: 0;
  }
}
.teapp-checkbox-wrapper-big {
  margin-bottom: 0;
}

.noauth-layout {
  .checkbox-group {
    margin-bottom: 20px;
  }
  .teapp-checkbox {
    font-size: 12px;
  }
}
