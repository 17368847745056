.transmission {
  background-color: white;
  padding: 15px;
}
.transmission-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
  .header-additional {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .header-links {
      a {
        button {
          margin: 5px 0;
        }
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .header-datetime {
      margin-right: 30px;
      display: flex;
      flex-wrap: wrap;
      height: 30px;
      font-size: 16px;
      .header-date {
        margin-right: 20px;
      }
      .header-date,
      .header-time {
        display: flex;
        align-items: center;
        background-position: left center;
        background-repeat: no-repeat;
        line-height: 1;
        padding-top: 5px;
      }
      .header-date {
        padding-left: 30px;
        background-image: url(../../../static/calendar.svg);
      }
      .header-time {
        padding-left: 28px;
        background-image: url(../../../static/time.svg);
      }
      @media screen and (min-width: 1400px) {
        font-size: 20px;
      }
    }
  }
  h1.header-title {
    font-size: 30px;
    margin-bottom: 0px;
    margin-right: 15px;
  }
}
.transmission-content {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1400px) {
  .transmission {
    padding: 30px;
  }
}

@media screen and (min-width: 1600px) {
  .transmission {
    padding: 35px;
  }
}

.chat {
  iframe {
    height: 500px;
    overflow: hidden;
  }
}
