@import "./theme-settings.scss";

.add-new-styles-here {
  color: blue;
}

* {
  font-variant: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
}

.CookieConsent button {
  background: white !important;
  color: black !important;
}

.ant-input-group-compact .ant-input {
  font-size: 13px;
}

.filters-switcher {
  .filters {
    button {
      &:not(:last-child):after {
        display: block;
        transition: 0.3s;
        content: "";
        width: 0%;
        height: 1px;
        background: white;
      }
      &:focus,
      &:hover {
        color: white;
        &:after {
          width: 70%;
        }
      }
      &.all.mixitup-filter-active {
        border-color: white;
        color: white;
        &:hover,
        &:focus {
          color: black;
          background: white;
        }
      }
    }
  }
}

.mini-header {
  display: flex;
  flex-wrap: wrap;
  .mini-header-text {
    margin-top: 30px;
    flex: 0 0 100%;
  }
}
.ant-input-group.ant-input-group-compact {
  .ant-input {
    font-size: 13px;
  }
}

.main-navbar .logo span {
  display: none;
  @media screen and (min-width: 576px) {
    display: block;
  }
}

.festival-card .event-image-container .event-image {
  background-position: center;
}
.teapp-checkbox {
  a {
    text-decoration: underline;
  }
}

.maintenance {
  .ant-result-icon {
    display: none;
  }
  .ant-result-title {
    margin-top: 100px;
  }
}

@media screen and (min-width: 576px) {
  .mini-header {
    .mini-header-text {
      margin-top: 5px;
      flex: 0 0 auto;
    }
  }
}
@media screen and (min-width: 768px) {
  .mini-header {
    .mini-header-text {
      margin-top: 5px;
      flex: 0 0 auto;
      h1,
      h2 {
        font-size: 23px;
        line-height: 1.2;
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .mini-header {
    .mini-header-text {
      margin-top: 10px;
    }
  }
}

.languageSelector {
  button {
    font-size: 14px !important;
    @media screen and (min-width: 1200px) {
      font-size: 17px !important;
    }
  }
}

.slick-slider {
  padding-bottom: 70px;
  background-image: url(./static/bg.jpg);
  background-size: cover;
  @media screen and (min-width: 576px) {
    padding-bottom: 90px;
    .slick-dots {
      bottom: 110px;
    }
  }
}
.filters-switcher {
  background-image: url(./static/bg.jpg);
  background-size: cover;
}
.auth-layout.full-width .content-wrapper:nth-child(2) {
  padding-top: 0;
  margin-top: -70px;
  z-index: 1001;
  position: relative;
  .content h1:first-child {
    color: white;
    margin-bottom: 20px;
    @media screen and (min-width: 576px) {
      margin-bottom: 40px;
    }
  }
  @media screen and (min-width: 576px) {
    margin-top: -90px;
  }
  @media screen and (min-width: 768px) {
    margin-top: -92px;
  }
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  background: #ee98b5;
}

.slick-slide {
  a {
    height: 100%;
  }
  .slide .slide-content {
    height: 100%;
    .slide-desc {
      height: 100%;
    }
    @media screen and (min-width: 768px) {
      height: auto;
      .slide-desc {
        height: auto;
      }
    }
  }
}

.content-wrapper .content {
  // color: white;
  // .node,
  // .ant-card,
  // .video-price {
  //   color: black;
  //   h1,
  //   h2,
  //   h3,
  //   h4,
  //   h5,
  //   h6 {
  //     color: black;
  //   }
  // }
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  .page-content,
  .ant-result-404 {
    color: white;
  }
}

footer {
  border-color: white;
}
footer .footer-wrapper {
  .footer-left {
    a,
    div {
      color: white;
      transition: 0.3s;
      &:hover,
      &:focus {
        color: white;
        opacity: 0.8;
      }
    }
  }
  .footer-right {
    .mini-footer {
      color: white;
      transition: 0.3s;
      &:hover,
      &:focus {
        color: white !important;
        opacity: 0.8;
      }
      img {
        filter: invert(1);
      }
    }
  }
}
.node-content .content-details .details-buttons button.green {
  background: #546a78;
}
.ant-btn.watch-button {
  background: #d4a4ba;
  color: white;
  border-color: transparent;
}

.video-card.not-available:after,
.video-card.not-available .video-status {
  color: transparent;
}
h1 {
  span {
    color: transparent;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.event-card .event-title,
button,
.ant-btn,
.filters-switcher h3,
.video-card .video-title,
.filters-switcher .filters button.all,
.main-navbar .logo span,
.slide .slide-desc .slide-title,
.mini-footer span,
.ant-input,
.festival-card .event-details .event-title a {
  font-weight: normal;
}
@media screen and (min-width: 576px) {
  h1 {
    font-size: 45px;
    display: inline-block;
  }
}
.filters-switcher .filters button.all,
.ant-btn {
  border-width: 2px;
}
.ant-result {
  background: white;
}
.slick-slider {
  z-index: 1000;
}
.video-card.not-available {
  opacity: 1;
}
.CookieConsent {
  z-index: 1002 !important;
}
.navbar-menu > ul li a {
  flex-direction: row-reverse;
  align-items: center;
  img {
    margin-right: 10px;
    margin-left: 0;
    margin-top: -4px;
  }
}
.navbar-menu > ul li a:hover,
.navbar-menu > ul li a:focus,
.navbar-menu > ul li.search-input button:hover img,
.navbar-menu > ul li.search-input button:focus img {
  color: white;
  filter: invert(24%) sepia(8%) saturate(3361%) hue-rotate(298deg)
    brightness(103%) contrast(87%);
}
.admin-menu li:hover img,
.admin-menu li.active img,
.admin-menu li:focus img {
  filter: invert(81%) sepia(6%) saturate(1666%) hue-rotate(323deg)
    brightness(80%) contrast(83%);
}
.main-navbar.searchbar-show .navbar-menu > ul li:last-child {
  margin-right: 0;
}

.event-card .event-payment-status .red {
  background-color: black;
  border-color: black;
  color: white;
}

@media screen and (min-width: 576px) {
  .auth-layout .content-wrapper .content #prezent > h2,
  .auth-layout .content-wrapper .content > h1,
  .auth-layout .content-wrapper .content > h2 {
    padding-top: 30px;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 992px) {
  .auth-layout .content-wrapper .content #prezent > h2,
  .auth-layout .content-wrapper .content > h1,
  .auth-layout .content-wrapper .content > h2 {
    margin-bottom: 40px;
  }
}
