$fontdir: "./static/fonts/";

@font-face {
  font-family: "reader-pro";
  src: url($fontdir + "reader-regular-pro.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "reader-pro";
  src: url($fontdir + "reader-bold-pro.woff2") format("woff2");
  font-weight: bold;
}

$headersFont: "reader-pro", sans-serif;
$textFont: "reader-pro", sans-serif;

$primary-color: black;
$header-bg-color: black;
$green-color: #ee98b5;
$light-green-color: #eea2bb;
$blue-color: #546a78;
$light-blue-color: #5c6d79;
$orange-color: #f5af17;
$light-orange-color: #f5af17;
$red-color: #cf3d3d;
$light-red-color: #cf3d3d;
$grey-color: #666;
$placeholder-color: #747474;
$wrapper-bg-color: black;

$navbarLogoWidthSm: 100px;
$navbarLogoWidthMd: 145px;
$navbarLogoWidthLg: 170px;

$miniNavbarLogoWidthSm: 145px;
$miniNavbarLogoWidthMd: 170px;
$miniNavbarLogoWidthLg: 170px;

$navbarDrawerBreakpoint: 900px;

// wrapper min height
// frontpage
$wrapperFWMinheightXs: 100px;
$wrapperFWMinheightSm: 100px;
$wrapperFWMinheightMd: 145px;
$wrapperFWMinheightLg: 145px;
$wrapperFWMinheightXl: 195px;
// static page
$wrapperMinheightXs: 80px;
$wrapperMinheightSm: 80px;
$wrapperMinheightMd: 125px;
$wrapperMinheightLg: 125px;
$wrapperMinheightXl: 175px;

$slideBg: black;
