@import "../../../theme-settings.scss";

.videos-list {
  .visible svg {
    color: black;
  }
  .hidden svg {
    color: black;
  }
  .ant-card-meta-avatar {
    svg {
      height: 35px;
      width: 35px;
    }
  }
  iframe {
    max-width: 100%;
    max-height: 230px;
    padding-top: 15px;
  }
}
.formDrawer {
  z-index: 1002;
}
.formDrawer .ant-drawer-content-wrapper {
  max-width: 500px;
  margin-top: 59px;
  iframe {
    max-width: 100%;
    height: 200px;
  }
  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
}
.formDrawer.codeFormDrawer .ant-drawer-content-wrapper {
  max-width: 750px;
}
.event-list {
  .ant-table-cell:last-child {
    svg {
      width: 20px;
      height: 20px;
      margin: 5px;
      transition: 0.3s;
      &:hover,
      &:focus {
        fill: $primary-color;
      }
    }
  }
}
.video-select,
.access-select {
  p {
    margin-bottom: 5px;
    font-size: 16px;
  }
  select {
    border: 1px solid black;
    background: transparent;
    padding: 5px 10px;
  }
}
.user-manager {
  .ant-table-cell:last-child,
  .ant-list-item {
    svg {
      width: 30px;
      height: 30px;
      margin: 5px;
    }
  }
}
textarea {
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
  min-height: 200px;
}

.videoCard {
  .ant-card-body {
    padding: 0;
    .ant-card-meta {
      padding: 15px;
    }
    .video-details {
      padding: 0 10px;
      padding-bottom: 5px;
      color: $grey-color;
    }
  }
}

.event-admin-form,
.payment-list-form {
  label {
    min-width: 140px;
  }
  .ant-picker-range {
    height: 36px;
  }
  select[name="status"],
  .ant-picker-range {
    width: 260px;
  }
}
.gallery-select {
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  .anticon {
    margin-left: 5px;
  }
}
.dictionaryCard,
.aboutVersionsContainer {
  p,
  li {
    max-width: 800px;
  }
}

.anyGiftSettings {
  border: 1px solid grey;
  padding: 15px;
  .ant-row {
    display: block;
  }
}

table {
  white-space: pre-wrap;
}
